<template>
  <div class="MusicListHighquality">MusicListHighquality</div>
</template>

<script>
export default {
  name: "MusicListHighquality",
};
</script>

<style scoped>
</style>